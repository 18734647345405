import React from 'react';
import { motion } from 'framer-motion';

const Banner = () => {
  return (
    <section
      className="relative h-screen flex items-center justify-center bg-cover bg-center"
      style={{ backgroundImage: 'url("/images/banner_bg.jpeg")' }} 
    >
      {/* Overlay for the shade effect */}
      
      <div className="absolute inset-0 bg-black bg-opacity-70 z-10"></div>
     
      <motion.div
        className="relative text-center text-white z-20 px-4 sm:px-6 md:px-8 lg:px-12"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 0.5 }}
      >
      
  {/* <img className=" w-8 h-auto justify-center items-center h-screen"src="/images/NLT_LOGO.png" alt="logo" /> */}
  {/* <div className="flex justify-center">
  <img src="/images/NLT_LOGO.png" alt="logo" className="w-8 h-auto" />
</div> */}




        <motion.h1
          className="text-3xl sm:text-4xl bg-gradient-to-r from-blue-400 via-green-500 to-pink-500 bg-clip-text text-transparent z-20 md:text-6xl font-bold mb-4"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          NextLeap Tech
        </motion.h1><br></br>

        <motion.h1
          className="text-2xl sm:text-3xl md:text-3xl font-bold mb-8 z-20 text-white"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          Empower Your Business with Cutting-Edge Technology
        </motion.h1>
        <motion.p
          className="text-base sm:text-lg md:text-xl mb-8 z-20"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, delay: 0.7 }}
        >
          At Next Leap Tech, we specialise in providing comprehensive Web & App Development, SaaS Services, Data Engineering & Solutions, E-commerce Solutions, and Digital Marketing Services. Our mission is to empower businesses like yours to thrive in the digital landscape through tailored technology solutions.
        </motion.p>
        <motion.button
          className="px-4 py-2 sm:px-6 sm:py-3 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-lg shadow-lg transition-transform z-20"
          whileHover={{ scale: 1.05, rotate: 3 }}
          whileTap={{ scale: 0.95, rotate: -3 }}
          onClick={() => document.getElementById('main-content').scrollIntoView({ behavior: 'smooth' })}
        >
          Explore Now
        </motion.button>
      </motion.div>

      <motion.div
        className="absolute bottom-10 left-1/2 transform -translate-x-1/2 text-white z-20"
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 1.5, repeat: Infinity, repeatType: 'reverse' }}
      >
        <svg
          className="w-6 h-6 sm:w-8 sm:h-8 animate-bounce"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </motion.div>
    </section>
  );
};

export default Banner;
