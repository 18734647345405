import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import './Auditing.css';

function Auditing() {
  const [url, setUrl] = useState('');
  const [maxPages, setMaxPages] = useState(5);
  const [auditResults, setAuditResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [expanded, setExpanded] = useState(null);

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch('https://nextleaptech.com/api/send/send-otp', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error('Failed to send OTP');
      }

      setOtpSent(true);
      setError('');
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch('https://nextleaptech.com/api/send/verify-otp', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, otp }),
      });

      if (!response.ok) {
        throw new Error('Invalid OTP');
      }

      setOtpVerified(true);
      setError('');
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!url) {
      return setError('Please enter a URL');
    }

    setLoading(true);
    setError('');
    setAuditResults(null);

    try {
      const response = await fetch('https://nextleaptech.com/api/send/audit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ url, page_limit: maxPages }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch audit data');
      }

      const data = await response.json();
      setAuditResults(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const downloadExcel = () => {
    if (!auditResults || !auditResults.crawled_pages) return;

    const data = Object.entries(auditResults.crawled_pages).map(([url, result]) => ({
      URL: url,
      'HTTP Status Code': result['HTTP Status Code'],
      Title: result['Title'],
      'Meta Description': result['Meta Description'],
      'H1 Tags': result['H1 Tags'].join(', ') || 'None',
      'Images Missing Alt': result['Images Missing Alt'].join(', ') || 'None',
      'Canonical Tag': result['Canonical Tag'],
      'Word Count': result['Word Count'],
      'Additional Links': result['Additional Links'],
      'Page Load Time': result['page load time'],
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Audit Results');

    XLSX.writeFile(workbook, 'AuditResults.xlsx');
  };

  const toggleExpand = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <div className="audit-container">
      <h1>Web Audit Tool</h1>
      {!otpSent ? (
        <form onSubmit={handleEmailSubmit} className="audit-form">
          <h3>Enter your email to receive OTP</h3>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            required
          />
          <button type="submit" disabled={loading}>
            {loading ? 'Sending OTP...' : 'Send OTP'}
          </button>
        </form>
      ) : !otpVerified ? (
        <form onSubmit={handleOtpSubmit} className="audit-form">
          <h3>Enter OTP sent to your email</h3>
          <input
            type="text"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            placeholder="OTP"
            required
          />
          <button type="submit" disabled={loading}>
            {loading ? 'Verifying OTP...' : 'Verify OTP'}
          </button>
        </form>
      ) : (
        <form onSubmit={handleSubmit} className="audit-form">
          <h3>Enter URL and Page Limit</h3>
          <input
            type="url"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            placeholder="URL"
            required
          />
          <input
            type="number"
            value={maxPages}
            onChange={(e) => setMaxPages(Number(e.target.value))}
            placeholder="Max Pages"
            required
          />
          <button type="submit" disabled={loading}>
            {loading ? 'Starting Audit...' : 'Start Audit'}
          </button>
        </form>
      )}

      {error && <div className="error">{error}</div>}

      {auditResults && auditResults.crawled_pages && (
        <div className="audit-results">
          <h3>Audit Results</h3>
          <div className="results-cards">
            {Object.entries(auditResults.crawled_pages).map(([url, result], index) => (
              <div className="result-card" key={index} onClick={() => toggleExpand(index)}>
                <h4>{url}</h4>
                <div className="result-summary">
                  <p><strong>Status:</strong> {result['HTTP Status Code']}</p>
                  <p><strong>Title:</strong> {result['Title']}</p>
                  <p><strong>Page Load Time:</strong> {result['page load time']}</p>
                </div>
                {expanded === index && (
                  <div className="result-details">
                    <p><strong>Meta Description:</strong> {result['Meta Description']}</p>
                    <p><strong>H1 Tags:</strong> {result['H1 Tags'].join(', ') || 'None'}</p>
                    <p><strong>Images Missing Alt:</strong> {result['Images Missing Alt'].join(', ') || 'None'}</p>
                    <p><strong>Canonical Tag:</strong> {result['Canonical Tag']}</p>
                    <p><strong>Word Count:</strong> {result['Word Count']}</p>
                    <p><strong>Additional Links:</strong> {result['Additional Links']}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
          <button onClick={downloadExcel}>Download Excel</button>
        </div>
      )}
    </div>
  );
}

export default Auditing;
