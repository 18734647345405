import React from 'react';
import { useNavigate } from 'react-router-dom';
import './WebAppDevelopmentShowcase.css';

const Dataenigneering = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleButtonClick = () => {
    navigate('/contact'); // Navigate to the contact page
  };

  return (
    <div className="showcase-container">
      {/* Meta Description for SEO */}
      <meta name="description" content="Explore our Software as a Service (SaaS) solutions designed to streamline your business operations. Contact us for a demo!" />
      
      {/* Hero Section */}
      <div className="hero-section">
        <h1>Scalable SaaS Solutions</h1>
        <p>
          At Next Leap Tech, we offer comprehensive SaaS Services that simplify your business processes. Our scalable, secure, and efficient software applications empower organizations to operate more effectively.
        </p><br>
        </br>
        <button className="cta-button" onClick={handleButtonClick}>Get Your Free Consultation</button> {/* Updated button */}
      </div>

      {/* Services Section */}
      <div className="services-section">
      
        <h2 className="text-3xl font-bold mb-4 grid place-items-center  bg-gray-100">Tailored SaaS Solutions</h2>
        <div className="services-grid">
          <div className="service-card left">
            <img src="/images/desk/web&app_10.jpeg" alt="Custom Web Development" />
            <div className="service-card-text">
              <h3 className="text-1xl font-bold mb-1">Custom SaaS Development</h3><br></br>
              <p>
                We design tailored applications specifically for your business needs. Our solutions are built to adapt as your business grows, ensuring you always have the tools necessary to succeed.
              </p>
            </div>
          </div>

          <div className="service-card right">
          <img className='mobile_view' src="images/desk/web&app_13.jpeg" alt="Mobile App Development" />
            <div className="service-card-text">
              <h3 className="text-1xl font-bold mb-1">Integration Services</h3><br></br>
              <p>
                Seamless integration with existing systems enhances functionality and improves workflow efficiency. We ensure that all components of your tech stack work together harmoniously.
              </p>
            </div>
            <img className='desktop_view' src="images/desk/web&app_13.jpeg" alt="Mobile App Development" />
          </div>

          <div className="service-card left">
            <img src="images/desk/web&app_14.jpeg" alt="E-Commerce Solutions" />
            <div className="service-card-text">
              <h3 className="text-1xl font-bold mb-1">Cloud Solutions</h3><br></br>
              <p>
                Access your applications from anywhere at any time with our robust cloud-based services. We prioritize security and reliability, so you can focus on what matters most—growing your business.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Why Choose Us Section */}
      <div className="about-us-section">
        <h2>Why Choose Us?</h2>
        <p>
        <strong>Scalability:</strong> Our solutions grow with your business, adapting to changing requirements without compromising performance.
        </p>
        <p>
        <strong>Data Security:</strong> We prioritize data protection and compliance, ensuring your sensitive information is safe from breaches.
        </p>
        <p>
        <strong>Expert Support:</strong> Our dedicated team is here to assist you at every step of the way, providing ongoing support and maintenance as needed.
        </p>
      </div>

      <div className="about-us-section">
        <h2 className="text-1xl font-bold mb-1">Client Success Stories</h2><br></br>
        <p>Switching to Next Leap Tech's SaaS solutions has streamlined our operations significantly. Their support team is always ready to help!<br></br>
            — Chandan  , Ashraya Solutions
        </p>
        </div>

      {/* CTA Section */}
      <div className="cta-section">
        <h2 className="text-1xl font-bold mb-1">Get Started Today!</h2><br></br>
        <p>
            Transform your operations with our SaaS solutions. <a href="/contact" className="cta-link">Contact us today</a> to discuss how we can help you achieve your business goals with our experts.
        </p>
      </div>
    </div>
  );
};

export default Dataenigneering;
