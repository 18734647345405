import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaBusinessTime } from "react-icons/fa6";
import { FcBusinessman } from "react-icons/fc";
import './SaaSPage.css';


const categories = [
  {
    image: 'images/desk/saas_11.jpeg',
    title: 'Web Auditing',
    description: 'Our Web Auditing services are for educational purpose only.',
    url: 'auditing'
  },
  {
    image: 'images/desk/pdf_convertion_1.jpeg',
    title: 'PDF Conversions',
    description: 'Got an image or PDF to be converted to text documents.',
    url: 'https://nextleaptech.com/pdf-conversions'
  },
  {
    image: 'images/desk/comingsoon_1.jpeg',
    title: 'Coming Soon',
    description: 'We’ve got our LAB cooking new Saas.',
    url: 'https://nextleaptech.com/coming-soon'
  },
];

const SaasPage = () => {
  const navigate = useNavigate();

  const handleCardClick = (url) => {
    window.open(url, '_blank');
  };

  const handleButtonClick = () => {
    navigate('/contact');
  };

  return (
    <div className="showcase-container">
      <meta name="description" content="Custom web and app development services tailored to enhance your business performance. Discover our expertise in creating responsive websites, mobile apps, e-commerce solutions, and more. Contact us for a consultation!" />
      
      <div className="hero-section">
        <h1>Transform Your Digital Presence with SaaS Solutions</h1>
        <p>
          Our custom solutions are designed to boost your business with high-performance SaaS Solutions.  we deliver results that drive growth and engagement.
        </p>
        <button className="cta-button" onClick={handleButtonClick}>
          <FaBusinessTime /> Get Your Free Consultation
        </button>
      </div>

      <div className="shop-by-category">
        <h1 className="main-heading">OUR PRODUCT</h1>
        <div className="category-cards-container">
          {categories.map((category, index) => (
            <div
              className="category-card"
              key={index}
              onClick={() => handleCardClick(category.url)}
            >
              <img src={category.image} alt={category.title} className="category-image" />
              <h2 className="category-title">{category.title}</h2>
              <p className="category-description">{category.description}</p>
            </div>
          ))}
        </div>
      </div>
      
      <br /><br /><br /><br /><br />
      <h2 className="text-3xl font-bold mb-4 grid place-items-center  bg-gray-100">Tailored SaaS Solutions</h2>
      <br /><br />
      <div className="services-grid">
        <div className="service-card left">
          <img src="images/desk/saas_12.jpeg" alt="Custom Web Development" />
          <div className="service-card-text">
            <h3 className="text-1xl font-bold mb-1">Custom SaaS Development</h3><br />
            <p>
              We design tailored applications specifically for your business needs. Our solutions are built to adapt as your business grows, ensuring you always have the tools necessary to succeed.
            </p>
          </div>
        </div>

        <div className="service-card right">
          <img className='mobile_view' src="images/desk/saas_14.jpeg" alt=" saas Integration Services" />
          <div className="service-card-text">
            <h3 className="text-1xl font-bold mb-1">Integration Services</h3><br />
            <p>
              Seamless integration with existing systems enhances functionality and improves workflow efficiency. We ensure that all components of your tech stack work together harmoniously.
            </p>
          </div>
          <img className='desktop_view' src="images/desk/saas_14.jpeg" alt="saas Integration Services" />
        </div>

        <div className="service-card left">
          <img src="images/desk/saas_16.jpeg" alt="cloud Solutions" />
          <div className="service-card-text">
            <h3 className="text-1xl font-bold mb-1">Cloud Solutions</h3><br />
            <p>
              Access your applications from anywhere at any time with our robust cloud-based services. We prioritize security and reliability, so you can focus on what matters most—growing your business.
            </p>
          </div>
        </div>
      </div>

      <div className="services-section">
        <h1 className="text-2xl sm:text-3xl font-bold mb-3 grid place-items-center">
          Our Expert Support
        </h1>
        <div className="services-grid">
          <div className="service-card">
            <img src="images/desk/customersupport_2.jpeg" alt="Custom Web Development" />
            <div className="service-card-text">
              <p>We are here for our customers whenever they are stuck or need assistance. Just reach out to us.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="about-us-section">
        <h2>Why Choose Us? <FcBusinessman /></h2>
        <p>
          Our team of skilled developers and designers is committed to exceeding your expectations. We utilize the latest technology and industry best practices to ensure that your digital presence is optimized for performance and search engine visibility.
        </p>
      </div>

      <div className="cta-section">
        <p>
          Ready to elevate your digital strategy? <a href="/contact" className="cta-link">Contact us today</a> to discuss how we can help you achieve your business goals with our expert web and app development services.
        </p>
      </div>
    </div>
  );
};

export default SaasPage;
